<template>
  <div class="popContainer">

    <div class="areaContent">


      <div class="targeta">


        <div v-show="step == 1" class="addClientIMG">
          <SecuenciaPage v-if="secuence" :level="1" />
        </div>


        <div v-show="step == 2" class="addUserIMG">
          <SecuenciaPage :level="2" ref="secuenceRef" />
        </div>

        <div v-show="step == 3" class="asignarClienteIMG">
          <SecuenciaPage :level="3" />
        </div>



        <div class="targetaLogin">
          <TitleParrafHeader2 :step="step" :updatingClient="updatingClient" :parrafo="parrafo" />


          <div class="targetaOpen">

            <AddTrasladoForm1 :serverClientId="'serverClientId'" @next="preSaveform1" :final_Clients="list_Client" :places_List="places_List" v-if="step == 1" @cerrar="close" />


            <AddTrasladoForm2 v-if="step == 2"  @cerrar="close"  @next="preSaveUser" style="min-height: 440px;" />
             


            <Step3Bind v-if="step == 3" @cerrar="close" @send="crearTodos" style="min-height: 400px;" />




          </div>

        </div>

      </div>

    </div>


  </div>

</template>

<script setup>

import { ref, defineEmits, defineExpose, onMounted } from 'vue'; //defineProps

import AddTrasladoForm1 from '@/components/Internal/Menu/Frames/traslado_Steps/AddTrasladoForm1.vue'
import AddTrasladoForm2 from '@/components/Internal/Menu/Frames/traslado_Steps/AddTrasladoForm2.vue'

import Step3Bind from '@/components/Internal/Menu/Frames/client_Steps/Step3Bind.vue'

import SecuenciaPage from '@/components/Internal/Menu//Frames/SecuenciaPage.vue'
import TitleParrafHeader2 from '@/components/Internal/Menu//Frames/TitleParrafHeader2.vue'

import { create_Client, create_User, placesListAll, clientsList } from '@/components/conexion/DataConector.js'

import Swal from 'sweetalert2'



const outGoingData = defineEmits(
  ['cerrar', 'form', 'updateClientList']
)

const parrafo = " Lorenpsn d Lorenpsn d Lorenpsn d Lorenpsn renpsn d Lorenpsn d Lorenpsn d Lorenpsn "


const secuenceRef = ref(null)

const secuence = ref(true)

const step = ref(1)

const updatingClient = ref(false)

const preforms = ref({
  traladoForm1: null,
  traladoForm2: null
})

const list_Client = ref([
  {
    client: {
      id: 1,
      type: "final",
      name: "Defecto Cliente 1",
      rnc: "rnc",
      contact: "contact",
      email: "email",
      phone: "phone",
      address: "address",
      web: "web"
    },
    usersByClient: 3
  },
  {
    client: {
      id: 2,
      type: "final",
      name: "Defecto Cliente 2",
      rnc: "rnc",
      contact: "contact",
      email: "email",
      phone: "phone",
      address: "address",
      web: "web"
    },
    usersByClient: 3
  }
])

const places_List = ref([
  {
    id: 2388931,
    clientId: 1,
    label: "Origen Defecto",
    radius: 288,
    latitude: 18.39053905,
    longitude: -70.04629971,
    address: "Calle 2ª, Zona ",
    show: true,
    startWeight: 0,
    endWeight: 0
  },
  {
    id: 2388930,
    clientId: 1,
    label: "destino Defecto",
    radius: 240,
    latitude: 18.42153715,
    longitude: -70.11888742,
    address: "San Cristóbal, República Dominicana, 91000",
    show: true,
    startWeight: 0,
    endWeight: 0
  }
])


function restore() {

  step.value = 1
  updatingClient.value = false
  secuence.value = true

  preforms.value.traladoForm1 = null
  preforms.value.traladoForm2 = null
}


function close() {

  restore();
  outGoingData('cerrar')
}


function preSaveform1(form1){


  preforms.value.traladoForm1 = form1

  console.log( preforms.value)

    //userId: 1, 
    //serverClientId: 3, 

     
   // bl: "AC54365DF453", //parte dos

  //timeRequest: "2025-01-16 17:00:00Z"  NO VA

   step.value = 2
}




function preSaveUser(form2) {

  preforms.value.traladoForm2 = form2

}





function consultarPlaces() {
  placesListAll().then(listPlaces => {

    if (listPlaces.success) {

      places_List.value = listPlaces.places
    }
  })
    .catch(error => {
      console.log(error)

    })
    .finally(() => {

      /*    console.log(places_List.value)*/
      console.log("Finalizo el request")
    })


}

function consultarListaClientes() {

  clientsList().then(clientList => {

    console.log(clientList)

    if (clientList.success) {
      list_Client.value = clientList.clients
    }


  }).catch(error => {
    console.log(error)
    console.log("Error al Hacer La peticion")
  })
    .finally(() => {
      console.log("consutla done")


    })

}

function crearTodos(bind) {


  console.log("Clients >>>>", preforms.value.client)

  create_Client(preforms.value.client).then(response => {

    if (response.message) {
      //alert()
      sms(response.message)


    } else {
      //console.log(response.data)
      sms("cliente Agregado Correctamente")
      //alert("cliente Agregado Correctamente")
      //close()
    }


  }).catch(error => {
    console.log(error)

  })
    .finally(() => {

      if (preforms.value.user) {

        console.log("Users >>>>", preforms.value.user)

        create_User(preforms.value.user).then(response => {

          if (response.message) {
            //alert(response.message)
            sms(response.message)


          } else {
            //console.log(response.data)
            // alert("cliente Agregado Correctamente")
            sms("Usuario Agregado Correctamente")
            close()
          }


        }).catch(error => {
          console.log(error)

        })
          .finally(() => {

            console.log("Actualiza ClientList")
            outGoingData('updateClientList')


          })
      } else {
        console.log("No existe el formulario usuario")

        console.log("Actualiza ClientList")
        outGoingData('updateClientList')

      }

    })


  console.log("se bind todo", bind)
  /*  
    setTimeout(()=>{
       close();
    },3000)*/



}



onMounted(() => {

  consultarPlaces()
  consultarListaClientes()


})



/*Upadate Client*/



function sms(texto) {

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });
  Toast.fire({
    icon: "success",
    title: texto
  });

}



defineExpose({})

</script>

<style scoped>
.popContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 20;
}

.areaContent {
  backdrop-filter: blur(3px);
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  min-width: 100%;
}


.targeta {
  display: flex;
  background-color: transparent;
  border-radius: 10px;
  box-shadow: -2px 0px 5px 2px rgba(52, 52, 52, 0.4);
}

/*.videoContainer {
  height: 100%;
  background-color: blue !important;
  border-radius: 10px 0px 0px 10px;
  background: blue;
}
*/

.asignarClienteIMG {
  background-color: white;
  min-width: 400px;
  background-image: url('@/assets/fondos/asignarCliente.png');
  border-radius: 10px 0px 0px 10px;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  /*  background-size: 120%; */
  overflow: hidden;
}

.addUserIMG {
  background-color: white;
  min-width: 400px;
  background-image: url('@/assets/fondos/addUser.png');
  border-radius: 10px 0px 0px 10px;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  /*  background-size: 120%; */
  overflow: hidden;
}

.addClientIMG {
  background-color: white;
  min-width: 400px;
  background-image: url('@/assets/fondos/addClient.png');
  border-radius: 10px 0px 0px 10px;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  /*  background-size: 120%; */
  overflow: hidden;
}





.fondoIMG {

  /* max-height: 470px;*/
  height: 100%;
  max-width: 400px;



  object-fit: cover;
  /* Ajusta la imagen para cubrir el contenedor */
  object-position: center;
  /* Centra la imagen dentro del contenedor */
}


.parrafo {
  user-select: none;
  font-size: 14px;
  /*padding: 2%;*/
  color: grey;

  max-width: 80%;

  word-wrap: break-word;
  /* Opcional: permite cortar palabras largas si no caben */
  text-align: justify;
  line-height: 1;
  overflow-wrap: break-word;
  margin: 0;
}

.targetaOpen {
  border-radius: 0px 0px 10px 0px;
  height: 100%;
  padding-left: 25px;
  background: #f5f6f7;

}

img {
  /*  width: 100%;*/
  border-radius: 10px 0px 0px 10px;
  background-color: blue
}






.targetaLogin {
  display: flex;
  flex-direction: column;
  /*  justify-content: space-between;
  min-width: 60%;*/
  width: 100%;
  max-width: 600px;
  position: relative;
  background-color: white;
  border-radius: 0px 10px 10px 0px;
  box-shadow: 2px 0px 5px 2px rgba(52, 52, 52, 0.4);
  /*  padding: 40px;*/
}
</style>
