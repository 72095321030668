<template>
	<div class="expandible">

		<table class="table table-lg requests-table">


			<thead>
				<tr class="columnaName">
					<th class="titleTable">
						<input type="checkbox" class="checkIcon" />
						<span>No.</span>
					</th>
					<th class="titleTable">Contenedor</th>
					<th class="titleTable ">Precinto</th>
					<th class="titleTable ">Fecha de Inicio</th>
					<th class="titleTable ">Fecha Fin</th>
					<th class="titleTable ">Ruta</th>
					<th class="titleTable ">Estado</th>



				</tr>
			</thead>

			<tbody>

				<tr v-for=" (dato, index) in transferList" :key="index" class="hovertabla">

					<td class="datoTable" :class="{ 'listaSelected': dato.check }">

						<input type="checkbox" class="checkIcon" />

						<span>{{ dato.id }}</span>

					</td>

					<td class="datoTable " :class="{ 'listaSelected': dato.check }">
						{{ dato.containerId }}
					</td>

					<td class="datoTable " :class="{ 'listaSelected': dato.check }">{{ dato.precinto }} </td>

					<td class="datoTable " :class="{ 'listaSelected': dato.check }">{{ dato.startDate }}</td>

					<td class="datoTable " :class="{ 'listaSelected': dato.check }">{{ dato.endDate }}</td>



					<td class="datoTable " :class="{ 'listaSelected': dato.check }">
						<div>
							<i class="bi bi-circle-fill vineta" style="color: #72c9eb;"></i>

							<span v-if="dato.startPlace" class="miniLetra">{{ dato.startPlace }}</span>
							<span v-else class="miniLetra">{{ "N/A" }}</span>

							<img style="height: 25px; margin-left: 5px;" src="@/assets/icons/flechaAzul.svg">


							<i class="bi bi-circle-fill vineta"></i>

							<span v-if="dato.endPlace" class="miniLetra">{{ dato.endPlace }}</span>
							<span v-else class="miniLetra">{{ "N/A" }}</span>
						</div>


					</td>

					<td class="datoTable " :class="{ 'listaSelected': dato.check }">
						<span class="statusContainer "
							:style="{ color: getStatus(dato.status).colorText, backgroundColor: getStatus(dato.status).color }">

							{{ getStatus(dato.status).label }}
						</span>
						<i class="bi bi-pencil confiIcons"></i>
						<i class="bi bi-trash confiIcons"></i>
						<i class="bi bi-three-dots confiIcons"></i>
					</td>
				</tr>






			</tbody>
		</table>




	</div>
</template>

<script setup>

import { getStatus } from '@/components/utils.js'

const transferList = [
	{
		id: 1,
		containerId: "21112845545546",
		precinto: "1234895459",
		startDate: "1/10/2024 , 00:00PM",
		endDate: "1/10/2024 , 00:00PM",
		startPlace: "Puerto Caucedo",
		endPlace: "Maritima Dominicana (MARDOM)",
		status: 2
	},
	{
		id: 2,
		containerId: "21112845545546",
		precinto: "1234895459",
		startDate: "1/10/2024 , 00:00PM",
		endDate: "1/10/2024 , 00:00PM",
		startPlace: "Puerto Haina",
		endPlace: "Maritima Dominicana (MARDOM)",
		status: 3
	},
	{
		id: 2,
		containerId: "21112845545546",
		precinto: "1234895459",
		startDate: "1/10/2024 , 00:00PM",
		endDate: "1/10/2024 , 00:00PM",
		startPlace: "Puerto Caucedo",
		endPlace: "Maritima Dominicana (MARDOM)",
		status: 5
	}

]


</script>

<style scoped>
.expandible {
	padding-top: 15px;
	padding-left: 20px;
	padding-right: 20px;
	overflow: auto;
	max-height: 90%;
	width: 100%;
}

.vineta {
	font-size: 8px;
	color: #3aaa35;
	padding: 5px;
}

.miniLetra {
	font-size: 10px;
}

.titleTable {
	background-color: #f6f7f9;
	font-size: 14px;
	font-weight: 700;
}

.datoTable {
	font-size: 12px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.confiIcons {
	color: grey;
	font-size: 15px;
	padding-right: 15px;
	cursor: pointer;
	float: right;

}

.checkIcon {
	margin-right: 5px;
	font-size: 14px;
}

.columnaName {
	position: sticky;
	top: 0px;
}

.listaSelected {
	background-color: lightblue;
}

.hovertabla:hover td {

	background-color: #f6f7f9;
}

.statusContainer {
	background-color: #c6bbde;
	border-radius: 3px;
	width: auto;
	text-align: center;
	padding: 3px;
	color: #12387e;
	font-size: 14px;
	font-weight: 500;
	padding-left: 15px;
	padding-right: 15px;
}


/*  scrollbar stiyles width */

::-webkit-scrollbar {
	width: 10px;
}

/* scrollbar stiyles Handle */
::-webkit-scrollbar-thumb {
	background-color: #66b9d9;
	border-radius: 10px;
}
</style>