<template>

  <form @submit.prevent="enviar" class="formulario">

    <div class="row">

      <span class="subTexto">BL</span>


      <div class="col-10">
        <div>
          <input v-model="formData.bl" class="inputsearchBar" maxlength="20" placeholder="BL"
            type="text"><!-- required -->

        </div>




      </div>

      <div class="col-1">
        <div @click="addBL"
          style=" height: 100%; margin-top: -4px; margin-left:-10px; display: flex; justify-content:center; align-items: center;">
          <i class="bi bi-plus-square-fill" style="color: #41c237; font-size: 35px; cursor: pointer;"></i>

        </div>
      </div>

      <TablaAddingBL :blList="listBl" @removeBl="removeBL" />




    </div>



          <div class="col-12" style="padding-top: 10px; padding-bottom: 10px;">


        <button @click="outGoingData('cerrar')" type="button" class="btn btn-secundary mybtn"
          style="  background: #d7d7e3;   margin-right: 15px;">Cancelar</button>

        <button type="submit" class="btn btn-primary mybtn"> Siguiente </button>

      </div>

  </form>

</template>

<script setup>

import { ref, defineEmits } from 'vue';


import TablaAddingBL from '@/components/Internal/tablas/TablaAddingBL.vue'

const outGoingData = defineEmits(['cerrar', 'next'])


const listBl =ref( new Map())

//set
listBl.value.set('1234557Bfe222', {text: "1234557Bfe222", id:1});
listBl.value.set('1234557Bfe223',{text: "1234557Bfe223", id:2})

console.log(listBl.value)

const formData = ref(
{
  bl: "", //AC54365DF453
})


function removeBL(id){
  console.log("voy a remover el BL", id)
}

function addBL(){
  listBl.value.set(formData.value.bl, {text: formData.value.bl, id:1});
}

function enviar() {

  
  outGoingData('next', formData.value);
  




}


</script>

<style scoped>
.formulario {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  background: #f5f6f8;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
}

.inputsearchBar {

  min-width: 100%;
  padding: 9px;
  box-shadow: 0px 0px 5px 1px rgba(233, 236, 239, 0.8);
  margin-bottom: 8px;
  border-radius: 5px;
  border: none;
  outline: none;
}

.campoSelected{
  border: solid 1px red;
}

option {
  color: grey;
}


.mybtn {
  background: #56bcda;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  border: none;
}


.subTexto {
  padding-left: 20px;
  margin-bottom: 5px;
  user-select: none;
  font-weight: 600;
  font-size: 15px;
}
</style>