<template>

  <form @submit.prevent="enviar" class="formulario">

    <div class="row">


      <span class="subTexto" style="color: grey;">{{incomingData.serverClientId}}</span>

      <span class="subTexto">Cliente</span>

      <div class="col-6">
        <div>

          <select id="clickme" class="inputsearchBar" :class="{'campoSelected':errors.finalClientId}"  style="width: 100%;" v-model="formData.finalClientId" required>

            <option :value="null" selected disabled default>Cliente Final</option>

            <option v-for=" (data, index) in incomingData.final_Clients" :key="index" :value="data.client.id">{{ data.client.name }}</option>



          </select>

        </div>


      </div>

      <div class="col-6">
        <div>

          <select id="clickme" class="inputsearchBar" :class="{'campoSelected':errors.type}"  style=" width: 100%;" v-model="formData.type">

            <option :value="null" selected disabled default>Tipo de Traslado</option>

            <option v-for=" (rol, index) in traslado_type" :key="index" :value="rol.value">{{ rol.label }}</option>

          </select>


        </div>

      </div>

      <div class="col-6">
        <div>

          <select id="clickme" class="inputsearchBar" :class="{'campoSelected':errors.startPlace}" style=" width: 100%;" v-model="formData.startPlace">

            <option :value="null" selected disabled default>Origen</option>

            <option v-for=" (rol, index) in incomingData.places_List" :key="index" :value="rol.id">{{ rol.label }}</option>



          </select>


        </div>

      </div>

      <div class="col-6">
        <div>

          <select id="clickme" class="inputsearchBar" :class="{'campoSelected':errors.endPlace}" style=" width: 100%; " v-model="formData.endPlace">

            <option :value="null" selected disabled default>Destino</option>

            <option v-for=" (rol, index) in incomingData.places_List" :key="index" :value="rol.id">{{ rol.label }}</option>

          </select>


        </div>

      </div>




      <div class="col-7">
        <div>

          <input v-model="formData.address" class="inputsearchBar" maxlength="40" placeholder="Direccion del Destino"
            type="text">
        </div>

      </div>

      <div class="col-5">
        <div>

          <input v-model="formData.city" class="inputsearchBar" maxlength="40" placeholder="Ciudad"
            type="text">
        </div>

      </div>

      <div class="col-7">
        <div>

          <input v-model="formData.unitPrice" class="inputsearchBar" maxlength="40" placeholder="Tarifa"
            type="text">
        </div>

      </div>

      <div class="col-5">
        <div>

          <input v-model="formData.timeTravelEst" class="inputsearchBar" type="date" value="2025-01-22" min="2024-01-01"
            max="2025-12-31" />

        </div>

      </div>


      <div class="col-12">
        <div>

            <textarea class="inputsearchBar" placeholder="comentario" style="min-height: 80px;" v-model="formData.note" rows="4" cols="50">
            </textarea>

        </div>




      </div>



      <div class="col-12" style="padding-top: 10px; padding-bottom: 10px;">


        <button @click="outGoingData('cerrar')" type="button" class="btn btn-secundary mybtn"
          style="  background: #d7d7e3;   margin-right: 15px;">Cancelar</button>

        <button type="submit" class="btn btn-primary mybtn"> Siguiente </button>

<!--         <span  style="padding-left: 10px; font-size: 12px; color: red; font-weight: 600;" v-for=" (error, index) in errors" :key="index" >{{ error }}</span> -->

      </div>

    </div>

    <div>

    </div>

  </form>

</template>

<script setup>

import { ref, defineProps, defineEmits } from 'vue';


const incomingData = defineProps(['places_List', 'final_Clients', 'serverClientId']);

const outGoingData = defineEmits(['cerrar', 'next'])

const formData = ref(
{
 
  finalClientId: null, //4,  
  unitPrice: "", //1800  //optional
  type: null, // "import",  
  startPlace: null,
  endPlace: null,  
  address: null,  // optional
  city: null,  //optional
  note: null,  // optional
  timeTravelEst: "2025-01-17 16:00:00Z", // optional

  //bl: "", //AC54365DF453
  //userId: 1, 
  //serverClientId: 3, 

  //timeRequest: "2025-01-16 17:00:00Z"  NO va
})

const errors=ref({
  finalClientId:false,
  type:false,
  startPlace:false,
  endPlace:false
})

const traslado_type = ref([
  {
    label: 'Importacion',
    value: "import"
  },
  {
    label: 'Deportacion',
    value: "deport"
  }
])


function restoreErrors(){
  errors.value.finalClientId=false
  errors.value.type=false
  errors.value.startPlace=false
  errors.value.endPlace=false
}

function enviar() {
 restoreErrors()

  if (formData.value.finalClientId === null) {

      errors.value.finalClientId=true;
    
  }

  if (formData.value.type === null) {

      errors.value.type=true;
    
  }

  if(formData.value.startPlace === null){
    errors.value.startPlace=true
  }

  if(formData.value.endPlace === null){
    errors.value.endPlace=true
  }

  if(errors.value.finalClientId ||  errors.value.type ||  errors.value.startPlace || errors.value.endPlace){
    console.log("Hay errors")

  }else{
      outGoingData('next', formData.value);
  }





}


</script>

<style scoped>
.formulario {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  background: #f5f6f8;
  padding-top: 10px;
  padding-right: 20px;
}

.inputsearchBar {

  min-width: 100%;
  padding: 9px;
  box-shadow: 0px 0px 5px 1px rgba(233, 236, 239, 0.8);
  margin-bottom: 8px;
  border-radius: 5px;
  border: none;
  outline: none;
}

.campoSelected{
  border: solid 1px red;
}

option {
  color: grey;
}


.mybtn {
  background: #56bcda;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  border: none;
}


.subTexto {
  user-select: none;
  font-weight: 600;
  font-size: 15px;
}
</style>