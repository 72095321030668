let hoy = new Date();
let ayer = new Date(hoy.getTime() - (24 * 60 * 60 * 1000) * 2);
import axios from 'axios';
import jwtDecode from "jwt-decode";

//axios.defaults.withCredentials = true; // Permite enviar credenciales como cookies
const backEnd = process.env.VUE_APP_API;
//const backEnd= "http://10.0.0.172:9413";
//-----------  <<<<<<<< Prod >>>>>>>> ----------------------
/*
  import axios from 'axios';
  const backEnd = process.env.VUE_APP_API;        
//const backEnd= "http://10.0.0.172:9000";
*/
//----------- <<<<<<<<<  Test   >>>>>>>>>> ----------------------
import {
    statesCountGeneral,
    statesCountGeneralFrame,
    transfersList,
    transfersListFrame,
    transferBlitslist,
    devicesAllStats, 
    
     
} // updateUser placesList  authLogOut rolesAll openCandado authLogin  candadoList clientCreate  userCreate    usersAll clientsAll
from './fakeAPI.js' 



export function decodeToken(token) {
  try {
    return jwtDecode(token); 
  } catch (error) {
    console.error("Error decoding token:", error);
    return null;
  }
}

//------------- | metodos |-------------------------
export async function transfers_list(hash) {
    let data = {
        success: false
    };
    await transfersList(hash).then(response => {
        //await axios.get(backEnd + `/v1/transfers/list/${hash}`).then(response => {
        if (response.data.success) {
            data = response.data
        }
        // console.log(response.data)
    }).catch(error => {
        console.log(error)
    });
    return data
}
export async function auth(params) {
    let data = {
        success: false
    };
    //await authLogin(params.username, params.password ).then(response => {  { withCredentials: true }
    await axios.post(backEnd + `/api/v1/auth/login`, params).then(response => {
        if (response.data.success) {
            data = {
                success: true
            };
        }
    }).catch(error => {
        console.log(error)
        return data = false
    });
    return data
}

export async function logOut() {
    let data = {
        success: false
    };
    //await authLogOut().then(response => {
    await axios.get(backEnd + "/api/v1/auth/logout",).then(response => { 
        console.log(response.data)

        if (response.data.success) {
            data = response.data
        }
        // console.log(response.data)
    }).catch(error => {
        console.log(error)
    });
    return data
}

export async function devicesAll() {
    let data = {
        success: false
    };
    //await candadoList().then(response => {
    await axios.get(backEnd + "/api/v1/devices/all",).then(response => { //{ withCredentials: true }
        console.log(response.data)


        if (response.data.success) {
            data = response.data
        }
        // console.log(response.data)
    }).catch(error => {
        console.log(error)
    });
    return data
}

export async function placesListAll() {
    let data = {
        success: false
    };
    //await placesList().then(response => {
    await axios.get(backEnd + "/api/v1/places/list/1",).then(response => {
        console.log(response.data)


        if (response.data.success) {
            data = response.data
        }
        // console.log(response.data)
    }).catch(error => {
        console.log(error)
    });
    return data
}


export async function clientsList() {
    let data = {
        success: false
    };
    //await clientsAll().then(response => {
    await axios.get(backEnd + "/api/v1/clients/all",).then(response => { 
        console.log(response.data)


        if (response.data.success) {
            data = response.data
        }
        // console.log(response.data)
    }).catch(error => {
        console.log(error)
    });
    return data
}

export async function usersList() {
    let data = {
        success: false
    };
    //await usersAll().then(response => {
    await axios.get(backEnd + "/api/v1/users/all",).then(response => { 
        console.log(response.data)

        if (response.data.success) {
            data = response.data
        }
        // console.log(response.data)
    }).catch(error => {
        console.log(error)
    });
    return data
}


export async  function update_Users(form, user_id) {
    let data
    //await updateUser(form, user_id).then(response => {
    await axios.put(backEnd + `/api/v1/users/update/${user_id}`, form).then(response => {
        console.log(response.data)

         data = response

    }).catch(error => {

        console.log(error.code)

        switch (error.code) {

        case 'ERR_NETWORK':
            console.log("Error de conexion")
            break; 

        case 'ERR_BAD_REQUEST':
            console.log(error.response.data)

            data=error.response.data
                
            break  

        default:
            console.log("Error de conexion General")

            break;   
  }

    })
    .finally(() => {
       /* data = "text"*/

    })

           return data ;  
   
}


export async function rolesList() {
    let data = {
        success: false
    };
    //await rolesAll().then(response => {
    await axios.get(backEnd + "/api/v1/roles/all",).then(response => { 
        console.log(response.data)

        if (response.data.success) {
            data = response.data
        }
        // console.log(response.data)
    }).catch(error => {
        console.log(error)
    });
    return data
}

export async  function create_Client(form) {
    let data
    //await clientCreate().then(response => {
    await axios.post(backEnd + `/api/v1/clients/create`, form).then(response => {
        console.log(response.data)

         data = response

    }).catch(error => {

        console.log(error.code)

        switch (error.code) {

        case 'ERR_NETWORK':
            console.log("Error de conexion")
            break; 

        case 'ERR_BAD_REQUEST':
            console.log(error.response.data)

            data=error.response.data
                
            break  

        default:
            console.log("Error de conexion General")

            break;   
  }

    })
    .finally(() => {
       /* data = "text"*/

    })

           return data ;  
   
}

export async  function update_Client(form, client_id) {
    let data
    //await clientCreate().then(response => {
    await axios.put(backEnd + `/api/v1/clients/update/${client_id}`, form).then(response => {
        console.log(response.data)

         data = response

    }).catch(error => {

        console.log(error.code)

        switch (error.code) {

        case 'ERR_NETWORK':
            console.log("Error de conexion")
            break; 

        case 'ERR_BAD_REQUEST':
            console.log(error.response.data)

            data=error.response.data
                
            break  

        default:
            console.log("Error de conexion General")

            break;   
  }

    })
    .finally(() => {
       /* data = "text"*/

    })

           return data ;  
   
}



export async  function create_User(form) {
    let data

    //await userCreate().then(response => {
    await axios.post(backEnd + `/api/v1/users/create`, form).then(response => {
        console.log(response.data)

         data = response

        // console.log(response.data)
    }).catch(error => {

        console.log(error.code)

        switch (error.code) {

        case 'ERR_NETWORK':
            console.log("Error de conexion")
            break; 

        case 'ERR_BAD_REQUEST':
            console.log(error.response.data)
            console.log(error.response.data.message)

            data=error.response.data
                
            break  

        default:
            console.log("Error de conexion General")

            break;   
  }

    })
    .finally(() => {
       /* data = "text"*/

    })

           return data ;  
   
}

export async function openSeal(params, pin) {

    let data = {
        success: false
    };

    params.pin=pin

    //await openCandado(params ).then(response => { 
    await axios.post(backEnd + `/api/v1/devices/openSeal`, params).then(response => {
        if (response.data.success) {
            data = {
                success: true
            };
        }
    }).catch(error => {
        console.log(error)
        return data = false
    });
    return data
}
 

export async function transfers_listFrame(hash) {
    let data = {
        success: false
    };
    await transfersListFrame(hash, ayer.toISOString(), hoy.toISOString()).then(response => {
        //await axios.get(backEnd + `/v1/transfers/list/${hash}/${ayer.toISOString()}/${hoy.toISOString()}`).then(response => {
        if (response.data.success) {
            data = response.data
        } else if (response.data.note) {
            data = response.data
            // console.log("No hay transfersList")
        }
        // console.log(response.data)
    }).catch(error => {
        console.log(error)
    });
    return data
}
export async function transfers_listRange(hash, from, to) {
    let data = {
        success: false
    };
    await transfersListFrame(hash, from, to).then(response => {
        //await axios.get(backEnd + `/v1/transfers/list/${hash}/${from}/${to}`).then(response => {
        if (response.data.success) {
            data = response.data
        } else if (response.data.note) {
            data = response.data
            // console.log("No hay transfersList")
        }
        // console.log(response.data)
    }).catch(error => {
        console.log(error)
    });
    return data
}
export async function counterGeneral(hash) {
    let data = {
        success: false
    };
    await statesCountGeneral(hash).then(response => {
        //await axios.get(backEnd + `/v1/states/count/${hash}`).then(response => {
        if (response.data.success) {
            data = response.data
        }
        // console.log(response.data)
    }).catch(error => {
        console.log(error)
    });
    return data
}
export async function counterCandado(hash) {
    let data = {
        success: false
    };
    await devicesAllStats(hash).then(response => {
        //await axios.get(backEnd + `/v1/devices/allStats/${hash}`).then(response => {
        if (response.data.success) {
            data = response.data
        }
        // console.log(response.data)
    }).catch(error => {
        console.log(error)
    });
    return data
}
export async function counterGeneralFrame(hash) {
    let data = {
        success: false
    };
    await statesCountGeneralFrame(hash, ayer.toISOString(), hoy.toISOString()).then(response => {
        //await axios.get(backEnd + `/v1/states/count/${hash}/${ayer.toISOString()}/${hoy.toISOString()}`).then(response => {
        if (response.data.success) {
            data = response.data
        } else if (response.data.note) {
            data = response.data
            //console.log("no existe")
        }
        // console.log(response.data)
    }).catch(error => {
        console.log(error)
    });
    return data
}
export async function counterGeneralRange(hash, from, to) {
    let data = {
        success: false
    };
    await statesCountGeneralFrame(hash, from, to).then(response => {
        //await axios.get(backEnd + `/v1/states/count/${hash}/${from}/${to}`).then(response => {
        if (response.data.success) {
            data = response.data
        } else if (response.data.note) {
            data = response.data
            //console.log("no existe")
        }
        // console.log(response.data)
    }).catch(error => {
        console.log(error)
    });
    return data
}
export async function blitsTranfers(hash, transfer_id) {
    let data = {
        success: false
    };
    await transferBlitslist(hash, transfer_id).then(response => {
        //await axios.get(backEnd + `/v1/transferBlits/list/${hash}/${transfer_id}`).then(response => {
        if (response.data.success) {
            data = response.data
        } else if (response.data.note) {
            data = response.data
            console.log("no existe")
        }
        // console.log(response.data)
    }).catch(error => {
        console.log(error)
    });
    return data
}
/*export async function transfers_list(params) {
    let data = []
    await axios.post(backEnd + '/v1/transfers/list', params).then(response => {
        data = response.data
    }).catch(error => {
        console.log(error)
        return data = false
    });
    return data
}*/